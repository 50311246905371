var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transfer-page"},[_c('layout',{class:{ blur: _vm.showPin },attrs:{"header":_vm.tokenByName.name,"receive":true},on:{"onBack":function($event){return _vm.$router.push('/')},"receive":function($event){_vm.dialog = true}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"8","md":"6","lg":"4"}},[_c('v-row',{staticClass:"pb-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"box-detail",attrs:{"elevation":"0"}},[_c('v-toolbar',{attrs:{"color":"#f47c94","dark":""}},[_c('div',[_vm._v(" Balance : "+_vm._s(_vm.parseUtillETHtoShow(_vm.tokenByName.balance))+" ")]),_c('v-spacer'),_c('div',[_c('v-img',{attrs:{"src":_vm.tokenByName.logoURI,"height":"30px","width":"30px","contain":""}})],1)],1),_c('v-card',{staticClass:"mt-5 mx-4 pa-5",attrs:{"outlined":""}},[_c('div',{staticClass:"caption"},[_vm._v("Token Address:")]),_c('div',[_vm._v(_vm._s(_vm.$route.params.token))])]),_c('div',{staticClass:"pa-4 mt-2"},[_c('v-form',{ref:"form",staticClass:"text-right",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"d-flex flex-row align-baseline"},[_c('v-text-field',{attrs:{"outlined":"","background-color":"white","rules":_vm.itemRules,"label":"หมายเลขกระเป๋า","required":""},model:{value:(_vm.form.to),callback:function ($$v) {_vm.$set(_vm.form, "to", $$v)},expression:"form.to"}}),_c('div',[_c('v-btn',{attrs:{"dark":"","icon":"","to":("/scan?tokenAddress=" + (_vm.$route.params.token))}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("qr_code")])],1)],1)],1),_c('v-text-field',{attrs:{"type":"number","outlined":"","background-color":"white","rules":[
                      function (v) { return !!v || 'กรุณากรอกข้อมูล'; },
                      function (v) { return parseFloat(v) > 0 || 'จำนวนต้องมากกว่า 0'; },
                      function (v) { return parseFloat(v) <=
                          parseFloat(_vm.parseUtillETH(_vm.tokenByName.balance)) ||
                        'จำนวนไม่ถูกต้อง'; } ],"label":"จำนวน","required":""},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":_vm.reset}},[_vm._v(" เคลียร์ ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.valid,"color":"#f47c94"},on:{"click":_vm.validate}},[_c('span',{staticClass:"white--text"},[_vm._v(" โอนเหรียญ ")])])],1)],1)],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPage),expression:"showPage"}],staticClass:"mt-30",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" history ")]),_c('span',{staticClass:"ml-1"},[_vm._v("ประวัติการทำรายการ")])],1),_c('v-card',{staticClass:"box-detail mt-2",attrs:{"elevation":"0"}},[(_vm.historyByToken.length > 0)?_c('div',_vm._l((_vm.historyByToken),function(history,i){return _c('history-item',{key:'historyByToken' + i,attrs:{"hash":history.hash,"from":history.from,"timeStamp":history.timeStamp,"tokenSymbol":history.tokenSymbol,"value":history.value,"lastChild":i >= _vm.historyByToken.length - 1}})}),1):_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"100px"}},[_vm._v(" ไม่มีข้อมูล... ")])])],1)],1)],1)]},proxy:true}])}),(_vm.showPin)?_c('pin-pad',{attrs:{"header":"กรุณาใส่รหัส","verify":true,"backward":true},on:{"goback":function($event){_vm.showPin = false},"verifyPin":_vm.sendToken}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"350"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('my-qrcode',{attrs:{"value":JSON.stringify({
          type: 'transfer',
          to: _vm.ethereumAddress,
          contractAddress: _vm.tokenByName.address,
        })}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }